import React from "react"
import { MdOutlineSchool } from "react-icons/md"
import { FaCheck } from "react-icons/fa";

import AppMaxWidth from "components/AppMaxWidth/AppMaxWidth"
import Button from "components/Button"
import imgPrepare from "assets/imgs/preparez-vous.png"
import { TROUVER_FORMATION_PATH } from "routes/navigation/navigationPaths";
import { useNavigate } from "react-router-dom";

const Card = ({ title, description }) => {
    return <div className="bg-white py-4 drop-shadow-md flex items-center gap-4 px-3 rounded-lg mb-4">
        <div className="bg-blue min-w-[40px] h-10 rounded-lg flex items-center justify-center">
            <FaCheck color="white" />
        </div>
        <div>
            <p className="font-bold text-md sm:text-base">{title}</p>
            <div className="flex gap-2">
                <div className="w-[6px] h-[5px] bg-grayD rounded-full mt-2"></div>
                <span className="text-grayD text-[12px] font-medium sm:text-sm">{description}</span>
            </div>
        </div>
    </div>
}

const TrouverMaVoie = () => {
    const navigate = useNavigate()
    return (
        <AppMaxWidth>
            <div className="grid grid-cols-1 lg:grid-cols-2 sm:px-10 gap-10">
                <div>
                    <img src={imgPrepare} alt="Projet professionnel" className="w-full" />
                </div>
                <div>
                    <h3
                        className="text-3xl font-semibold text-blackSecondary"
                    >
                        Préparez votre projet professionnel avec nous
                    </h3>
                    <div className="w-24 h-1 bg-blackSecondary my-3"></div>
                    <p className="mt-2 mb-5 tracking-wider leading-7">
                        <span className="font-semibold">Unistaf</span> est la plateforme qui aide les étudiants à trouver l'école
                        parfaite et la formation adaptée à leurs ambitions. Connecte-toi avec ta future école en quelques clics.
                    </p>
                    <div className="my-4">
                        <Card
                            title={"Orientation personnalisée"}
                            description={"Avec un large choix de formations, bénéficie d'une orientation qui répond à tes besoins et aspirations et trouve ta voie idéale."}
                        />
                        <Card
                            title={"Dépôt de dossier en ligne"}
                            description={"Avec Unistaf, gère tout ton process d'inscription de A à Z depuis ton canapé"}
                        />
                    </div>
                    <Button
                        onClick={() => navigate(TROUVER_FORMATION_PATH)}
                        className="bg-blue hover:bg-[#003f96] text-white text-center h-11 px-5 text-md font-semibold"
                        title={"Trouver ma voie"}
                        icon={{
                            name: MdOutlineSchool,
                            color: "#FFF",
                            size: 25
                        }}
                    />
                </div>
            </div>
        </AppMaxWidth>
    )
}

export default TrouverMaVoie
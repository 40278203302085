import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    // documents
    nom: yup.string().required("Nom document obligatoire"),
    fichier: yup.string().required("Fichier obligatoire"),
  })
  .required();


const DocumentAFournirProvider = ({ children, initialValues }) => {
  const methods = useForm({
    values: {
      ...initialValues
    },
    defaultValues: { ...initialValues },
    resolver: yupResolver(schema),
  })
  return (
    <FormProvider {...methods}>{children}</FormProvider>
  )
}

export default DocumentAFournirProvider
import { useRegisterUserMutation } from "actions/auth/mutations"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setCurrentUser } from "redux/features/userslice"
import { LOGIN_PATH, MES_CANDIDATURES_PATH, NEW_CANDIDATURES_PATH } from "routes/navigation/navigationPaths"
// import { MES_CANDIDATURES_PATH } from "routes/navigation/navigationPaths"
// NEW_CANDIDATURES_PATH

export const useInscription = () => {
  const [inscriptionMutation, { isLoading: isInscriptionLoading }] = useRegisterUserMutation()
  const dispatch = useDispatch()
  const { selectedFormation } = useSelector((state) => state.trouverFormation)
  const navigate = useNavigate()

  const handleInscription = (data) => {
    /**
    /api/visiteur/candidatures/
      {
        "nom":"Ogechi",
        "prenom":"Alice",
        "email":"alice@yopmail.com",
        "password":"Lechrist@1",
        "civilite":"femme",
        "formation":1
      }
     */
    // console.log({ ...data, formation: selectedFormation.id });
    // return

    inscriptionMutation({ ...data, formation: selectedFormation.id }).unwrap().then((res) => {
      console.log("from inscription");
      dispatch(setCurrentUser(res))
      if (selectedFormation) {
        navigate(MES_CANDIDATURES_PATH + "/" + NEW_CANDIDATURES_PATH)
      } else {
        navigate(LOGIN_PATH)
      }
    })
      .catch((err) => {
        console.log({ err });
    })
  }

  return { handleInscription, isInscriptionLoading }
}
import React from "react"
import styled, { keyframes } from "styled-components";

const l20_1 = keyframes`
  0% { clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%); }
  12.5% { clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%); }
  25% { clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%); }
  50% { clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%); }
  62.5% { clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%); }
  75% { clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%); }
  100% { clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%); }
`;

const l20_2 = keyframes`
  0% { transform: scaleY(1) rotate(0deg); }
  49.99% { transform: scaleY(1) rotate(135deg); }
  50% { transform: scaleY(-1) rotate(0deg); }
  100% { transform: scaleY(-1) rotate(-135deg); }
`;

// Create a styled component for the loader
const Loader = styled.div`
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid rgba(17, 17, 165, 1);
  animation:
    ${l20_1} 0.8s infinite linear alternate,
    ${l20_2} 1.6s infinite linear;
`;

const LoadingIndicator = () => {
  return (
    <div className="flex justify-center">
      <Loader />
    </div>
  )
}

export default LoadingIndicator
import React from "react"
import { useNavigate, useParams } from "react-router-dom"

import AnimateOnRender from "components/AnimateRender/AnimateOnRender"
import DiplomeCardItem from "components/Diplomes/DiplomeCardItem"
import CandidatureStatutBadge from "../CandidatureStatutBadge"
import useCandidature from "./useCandidature"
import { getFileName } from "utils/getFileName"
import pdfImg from "assets/imgs/pdf.png"
import PersonalInFo from "./PersonalInFo"
import Separator from "components/UI/Separator"
import Button from "components/Button"
import { FaArrowLeft } from "react-icons/fa"
import LoadingIndicator from "components/Loader/LoadingIndicator"
import RequestError from "components/RequestError/RequestError"

const CandidaturesDetails = () => {
  const { slug_candidature } = useParams()
  const { data, isLoading, isError } = useCandidature(slug_candidature)
  const navigate = useNavigate()
  // console.log({ data });

  return (
    <AnimateOnRender>
      <Button onClick={() => navigate(-1)} title={"Retour"} icon={{ name: FaArrowLeft, size: 15, color: "#1111a5" }} className="bg-white mb-3 shadow-md" />
      {
        isLoading ? <LoadingIndicator /> : isError ? <RequestError /> : <div className="flex flex-wrap-reverse gap-4">
          <div className="min-w-[300px] flex-1">
            <PersonalInFo />
          </div>
          <div style={{ flex: 2 }} className="bg-white rouded-md p-4">
            <div className="py-3">
              <h2 className="text-2xl font-semibold">Formation</h2>
              <DiplomeCardItem diplome={data.formation} />
            </div>
            <Separator />
            <div className="py-3">
              <h2 className="text-2xl font-semibold">Statut</h2>
              <div className="flex items-center gap-2">
                <span>Statut de la demande :</span><CandidatureStatutBadge text={data.status} />
              </div>
            </div>
            <Separator />
            <div className="py-3">
              <h2 className="text-2xl font-semibold">Cursus scolaire</h2>
              <div className="flex gap-5 border-b-[1px] border-gray my-2">
                <div className="flex-1">
                  <p className="font-medium">Etablissement</p>
                </div>
                <div className="flex-1">
                  <p className="font-medium">Année</p>
                </div>
                <div className="flex-1">
                  <p className="font-medium">Ville/Pays</p>
                </div>
                <div className="flex-1">
                  <p className="font-medium">Diplôme</p>
                </div>
              </div>
              {
                data.etudiant.cursus.map((cursus) => {
                  return <div key={cursus.id} className="flex gap-5 my-2">
                    <div className="flex-1">
                      {/* <p>Etablissement</p> */}
                      <p>{cursus.etablissement}</p>
                    </div>
                    <div className="flex-1">
                      {/* <p>Année</p> */}
                      <p>{cursus.annee}</p>
                    </div>
                    <div className="flex-1">
                      {/* <p>Ville/Pays</p> */}
                      <p>{cursus.ville}</p>
                    </div>
                    <div className="flex-1">
                      {/* <p>Diplôme</p> */}
                      <p>{cursus.diplome}</p>
                    </div>
                  </div>
                })
              }
            </div>
            <Separator />
            <div className="py-3">
              <h2 className="text-2xl font-semibold">Documents</h2>
              <div className="mt-3">
                {
                  data.etudiant.documents.map((document) => {
                    return <a
                      href={process.env.REACT_APP_API_URL + document.fichier}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center gap-2 mb-3 bg-[#fafafd] w-fit p-3"
                      key={document.id}
                    >
                      <img width="30" height="30" src={pdfImg} alt={document.nom} />
                      <span className="text-sm font-bold text-blue">{getFileName(document.fichier)}</span>
                    </a>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      }
    </AnimateOnRender>
  )
}

export default CandidaturesDetails
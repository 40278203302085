import { ApiMananger } from "actions/rtkquery";

const diplomesApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        getDiplomes: build.query({
            query: () => "visiteur/diplomes/",
            invalidatesTags: ["DIPLOMES"]
        })
    }),
});

export const { useGetDiplomesQuery } = diplomesApi;
// !GERER L'AUTORISATION D'ACCESS A VOTRE APPLICATION

import { useSelector } from "react-redux";

/**
 * Definit l'authentification
 * @return {true|false}
 */
function useIsAuthenticate() {
    const { currentUser } = useSelector((state) => state.user)
    const isAuthenticate = currentUser?.token;

    console.log({ isAuthenticate });


    return isAuthenticate;
}

export default useIsAuthenticate;

import React, { memo } from "react"
import QuestionTitle from "../../../../components/TrouverFormation/QuestionTitle"
// import GridList from "react-flexible-list"
// import { trouverMaFormation } from "data/trouverMaFormation"
import TrouverFormationCard from "../../../../components/TrouverFormation/TrouverFormationCard"
import { useTrouverMaFormationeContexte } from "context/TrouverMaFormationeContexteProvider"
import { useLieux } from "./useLieux"
import LoadingIndicator from "components/Loader/LoadingIndicator"
import RequestError from "components/RequestError/RequestError"

const LieuFormationChoixCardItem = ({ lieu }) => {
    const { setLieuFormationChoice, trouverFormation } = useTrouverMaFormationeContexte()
    return <TrouverFormationCard
        active={trouverFormation.selectedLieuFormation?.slug === lieu.slug}
        customClass={"rounded-full text-sm"}
        onClickCard={() => setLieuFormationChoice(lieu)}
        content={() => <span className="font-semibold text-center">{lieu.libelle}</span>}
    />
}

const LieuEtudeListChoix = () => {
    const { villes, isError, isLoading } = useLieux();

    if (isLoading) {
        return <LoadingIndicator />
    }
    if (isError) {
        return <RequestError />
    }

    // console.log({ villes });

    return (
        <div>
            <QuestionTitle title={"Où souhaites-tu étudier ?"} />
            {/* <GridList
                resourceData={trouverMaFormation.lieuxFormation}
                resourceItem={(lieu) => <LieuFormationChoixCardItem lieu={lieu} />}
                cardWidth={240}
            /> */}
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {villes.results.map((lieu, index) => <LieuFormationChoixCardItem key={index} lieu={lieu} />)}
            </div>
        </div>
    )
}

export default memo(LieuEtudeListChoix)
import React from "react"
import { Table } from "antd";
import { useCandidatures } from "./useCandidatures";
import { useSelector } from "react-redux";
import { BsEye } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const CandidaturesPage = () => {
  const { currentUser } = useSelector((state) => state.user)
  const { data, isLoading } = useCandidatures(currentUser.user.slug)
  const navigate = useNavigate()

  console.log({ data });

  const columns = [
    {
      title: "Ecole",
      dataIndex: "formation",
      key: "ecole",
      render: (formation) => {
        return <div className="flex items-center gap-2">
          <img src={process.env.REACT_APP_API_IMG_URL + formation.ecole.logo} alt={formation.ecole.nom} />
          <span>{formation.ecole.nom}</span>
        </div>
      }
    },
    {
      title: "Formations",
      dataIndex: "formation",
      key: "formation",
      render: (formation) => {
        console.log({ formation });
        return <span>{formation.libelle}</span>
      }
    },
    {
      title: "Statut",
      dataIndex: "status",
      key: "status",
      render: (text) => <span className="rounded-full text-sm text-green3A font-semibold bg-grean3ARGB p-2 px-6">{text}</span>
    },
    {
      title: "Actions",
      dataIndex: "slug",
      key: "actions",
      render: (slug) => <div className="flex items-center gap-2">
        <div className="w-50  rounded-full bg-[#feaf144d] p-2">
          <BsEye size={25} color="rgba(254, 175, 20, 1)" onClick={() => navigate(slug)} />
        </div>
        <div className="w-50  rounded-full bg-[#eeb8b34d] p-2">
          <CiEdit size={25} color="rgba(35, 11, 52, 1)" />
        </div>
      </div>
    },
  ];
  return <div>
    <h2 className="font-semibold text-2xl mb-5">Mes candidatures ({data.count})</h2>
    <Table loading={isLoading} dataSource={data.results} columns={columns} />
  </div>;
}

export default CandidaturesPage
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    // informations personnelles
    prenom: yup.string().required("Prénom obligatoire"),
    nom: yup.string().required("Nom obligatoire"),
    email: yup.string().required("Email obligatoire"),
    civilite: yup.string().required("Genre obligatoire"),
    telephone: yup.string().required("Téléphone obligatoire"),
    // adresse: yup.string().required("Adresse obligatoire"),
    nationnalite: yup.string().required("Nationnalité obligatoire"),
    lieu_de_naissance: yup.string().required("Lieu de naissance obligatoire"),
    date_de_naissance: yup.string().required("Date de naissance obligatoire"),
    residence: yup.string().required("Résidence obligatoire"),
    ville: yup.string().required("Ville obligatoire"),
  })
  .required();


const InformationsPersonnellesProviver = ({ children, initialValues }) => {
  const methods = useForm({
    values: {
      ...getInitialValues(initialValues)
    },
    defaultValues: {
      ...getInitialValues(initialValues)
    },
    resolver: yupResolver(schema),
  })
  return (
    <FormProvider {...methods}>{children}</FormProvider>
  )
}

export default InformationsPersonnellesProviver

const getInitialValues = (data) => {
  return {
    prenom: data.prenom,
    nom: data.nom,
    email: data?.email,
    civilite: data?.civilite,
    telephone: data?.telephone,
    nationnalite: data?.nationnalite,
    lieu_de_naissance: data?.lieu_de_naissance,
    date_de_naissance: data?.date_de_naissance,
    residence: data?.residence,
    ville: data?.ville,
  }
}
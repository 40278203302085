import { ApiMananger } from "actions/rtkquery";

const ecolesApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        getEcoles: build.query({
            query: (params) => {
                return {
                    url: "visiteur/ecoles/",
                    params
                }
            },
            invalidatesTags: ["ECOLES"],
        }),
        getEcole: build.query({
            query: (slug) => {
                return {
                    url: `visiteur/ecoles/${slug}`,
                }
            },
            invalidatesTags: ["ECOLES"],
        })
    }),
});

export const { useGetEcolesQuery, useGetEcoleQuery } = ecolesApi;

import React from "react"
import { useSelector } from "react-redux";

import ProfileAvatar from "components/UI/ProfileAvatar";
import Separator from "components/UI/Separator";
import avatar from "assets/imgs/avatar.png"

const Row = ({ label, description }) => {
  return <div className="py-3">
    <span className="block text-[#084cb2] font-semibold">{label}</span>
    <span>{description}</span>
  </div>
}

const PersonalInFo = () => {
  const { currentUser } = useSelector((state) => state.user);
  console.log({ currentUser });

  return (
    <div className="bg-white rouded-md p-4 h-full">
      <div className="flex flex-col justify-center items-center">
        <ProfileAvatar avatar={avatar} />
        <p className="font-semibold">{currentUser?.user.prenom} {currentUser?.user.nom}</p>
      </div>
      <Row label={"Nationalité"} description={currentUser.user.nationnalite} />
      <Separator />
      <Row label={"Lieu de naissance"} description={currentUser.user.lieu_de_naissance} />
      <Separator />
      <Row label={"Date de naissance"} description={currentUser.user.date_de_naissance} />
      <Separator />
      <Row label={"Adresse email"} description={currentUser.user.email} />
      <Separator />
      <Row label={"N° de téléphone"} description={currentUser.user.telephone} />
      <Separator />
      <Row label={"Ville"} description={currentUser.user.ville} />
      <Separator />
      <Row label={"Résidence"} description={currentUser.user.residence} />
    </div>
  )
}

export default PersonalInFo
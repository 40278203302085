import Input from "components/Input"
import Select from "components/Select"
import React from "react"
import { useFormContext } from "react-hook-form"

const InformationsPersonnellesForm = () => {
  const { register, formState: { errors } } = useFormContext()
  return (
    <div>
      <div className="flex flex-wrap gap-4 mb-4">
        <Input
          label="Nom"
          register={{ ...register("nom") }}
          errorMessage={errors.nom?.message}
          placeholder="Yangala"
          inputContainerClass="flex-1"
        />
        <Input
          label="Prénom"
          register={{ ...register("prenom") }}
          errorMessage={errors.prenom?.message}
          placeholder="Isaac" inputContainerClass="flex-1"
        />
      </div>
      <div className="flex flex-wrap gap-4 mb-4">
        <Select
          containerClasse={"flex-1"}
          label="Genre"
          register={{ ...register("civilite") }}
          errorMessage={errors.civilite?.message}
          inputContainerClass="flex-1"
        >
          <option value="">Sélectionner</option>
          <option value="homme">Homme</option>
          <option value="femme">Femme</option>
        </Select>
        <Input
          label="Nationalité"
          register={{ ...register("nationnalite") }}
          errorMessage={errors.nationnalite?.message}
          placeholder="Ajouter votre nationalité"
          inputContainerClass="flex-1"
        />
      </div>
      <div className="flex flex-wrap gap-4 mb-4">
        <Input
          label="Lieu de naissance"
          register={{ ...register("lieu_de_naissance") }}
          errorMessage={errors.lieu_de_naissance?.message}
          placeholder="Kozoum"
          inputContainerClass="flex-1"
        />
        <Input
          type="date"
          label="Date de naissance"
          register={{ ...register("date_de_naissance") }}
          errorMessage={errors.date_de_naissance?.message}
          placeholder="03/01/1984"
          inputContainerClass="flex-1"
        />
      </div>
      <div className="flex flex-wrap gap-4 mb-4">
        <Input
          label="Ville"
          register={{ ...register("ville") }}
          errorMessage={errors.ville?.message}
          placeholder="Dakar"
          inputContainerClass="flex-1"
        />
        <Input
          type="text"
          label="Résidence"
          register={{ ...register("residence") }}
          errorMessage={errors.residence?.message}
          placeholder="Fass"
          inputContainerClass="flex-1"
        />
      </div>
      <div className="flex flex-wrap gap-4 mb-4">
        <Input
          type="phone"
          label="Numéro de téléphone"
          register={{ ...register("telephone") }}
          errorMessage={errors.telephone?.message}
          placeholder="789900098"
          inputContainerClass="flex-1"
        />
        <Input
          type="mail"
          label="Email"
          register={{ ...register("email") }}
          errorMessage={errors.email?.message}
          placeholder="isaac@gmail.com"
          inputContainerClass="flex-1"
        />
      </div>
    </div>
  )
}

export default InformationsPersonnellesForm
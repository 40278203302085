import Button from "components/Button"
import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { setDiplome, setDomaine, setFiliere, setLieuFormation } from "redux/features/trouverFormationSlice"

const TrouverMaFormationeContexte = createContext()

const TrouverMaFormationeContexteProvider = ({ children }) => {
  const [progressBar, setProgressBar] = useState(0)
  const { trouverFormation } = useSelector((state) => state)

  const [searchParams, setSearchParams] = useSearchParams();
  // TODO: utuliser Redux pour gerer le currentIndex
  // const [currentIndex, setCurrentIndex] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(Number(localStorage.getItem("currentIndex")) ?? 0)

  const dispatch = useDispatch();

  const previousStep = () => {
    setCurrentIndex((prev) => prev - 1)
  }

  const nextStep = () => {
    setCurrentIndex((prev) => prev + 1)
  }

  function setParams(query, value) {
    searchParams.set(`${query}`, value)
    setSearchParams(searchParams)
  }

  const setDomaineChoice = (domaine) => {
    setParams("domaine", domaine.slug)
    dispatch(setDomaine(domaine))
    if (domaine.slug !== trouverFormation.selectedDomaine?.slug) {
      dispatch(setFiliere(null))
      dispatch(setDiplome(null))
      dispatch(setLieuFormation(null))
    }
  }

  const setFiliereChoice = (filiere) => {
    console.log({ prev: trouverFormation.selectedFiliere, current: filiere })
    console.log({ filiere: filiere.slug === trouverFormation.selectedFiliere?.slug })
    setParams("filiere", filiere.slug)
    dispatch(setFiliere(filiere))
    if (filiere.slug !== trouverFormation.selectedFiliere?.slug) {
      dispatch(setDiplome(null))
      dispatch(setLieuFormation(null))
    }
  }

  const setDiplomeChoice = (diplome) => {
    dispatch(setDiplome(diplome))
    if (diplome.slug !== trouverFormation.selectedDiplome?.slug) {
      dispatch(setLieuFormation(null))
    }
  }
  const setLieuFormationChoice = (lieu) => {
    setParams("lieu", lieu.libelle)
    dispatch(setLieuFormation(lieu))
  }

  const contextValue = useMemo(() => {
    return {
      previousStep,
      nextStep,
      setDomaineChoice,
      setFiliereChoice,
      setDiplomeChoice,
      setLieuFormationChoice
    }
  }, [])

  useEffect(() => {
    // console.log("useEffect", trouverFormation.selectedDomaine)
    if (trouverFormation.selectedDomaine) {
      setParams("domaine", trouverFormation.selectedDomaine.slug)
    }
    if (trouverFormation.selectedFiliere) {
      setParams("filiere", trouverFormation.selectedFiliere.slug)
    }
    if (trouverFormation.selectedDiplome) {
      setParams("diplome", trouverFormation.selectedDiplome.slug)
    }
    if (trouverFormation.selectedLieuFormation) {
      setParams("lieu", trouverFormation.selectedLieuFormation.libelle)
    }
  }, [])
  useEffect(() => {
    localStorage.setItem("currentIndex", currentIndex)
    setProgressBar((currentIndex / (children.length - 1)) * 100)
  }, [currentIndex])

  return (
    <TrouverMaFormationeContexte.Provider value={{ ...contextValue, trouverFormation }}>
      {
        !!currentIndex && <Button
          onClick={previousStep}
          className="shadow-sm rounded-lg text-sm border mb-3 p-2"
          title={"Précédent"}
        />
      }
      {
        !!currentIndex && <div className="h-[4px] w-full bg-gray mb-6 rounded-md">
          <div
            style={{ width: progressBar + "%", transition: "all 300ms" }}
            className="h-[4px] bg-blue mb-4 rounded-md"
          >
          </div>
        </div>
      }
      <div className="mb-5">
        {
          Object.entries(trouverFormation).map((label, index) => {
            return !label[1] ? undefined : (label[1] && index > 0) ? <span key={index}> - {label[1].libelle}</span> : <span key={index}>{label[1].libelle}</span>
          })
        }
      </div>

      {children[currentIndex]}
    </TrouverMaFormationeContexte.Provider>
  )
}

export default TrouverMaFormationeContexteProvider

export const useTrouverMaFormationeContexte = () => {
  const ctx = useContext(TrouverMaFormationeContexte)
  return ctx
}
/* eslint-disable max-len */
import React from "react"
import { twMerge } from "tailwind-merge"

const Select = ({ label, children, extraClasse, containerClasse, register, errorMessage }) => {
    return (
        <div className={twMerge([containerClasse])}>
            <label htmlFor={label} className="block mb-1 text-md font-medium text-dark">{label}</label>
            <select
                {...register}
                id={label}
                className={twMerge(["border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 min-w-max h-11", extraClasse])}
            >
                {children}
            </select>
            {errorMessage && (
                <div style={{ marginTop: 10, fontSize: 13, color: "red" }}>
                    {errorMessage}
                </div>
            )}
        </div>
    )
}

export default Select
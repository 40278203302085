/*
|--------------------------------------------------------------------------
! All Your Application Navigations Paths Will Be Here
|--------------------------------------------------------------------------
*/

/**
 * @readonly
 * ! PUBLIC ROUTES
 */
export const PUBLIC_PATH = "/";
export const ECOLES_PATH = "/etablissements";
export const ECOLES_DETAILS_PATH = ":ecole_slug";
export const FORMATIONS_PATH = "/formations";
export const TROUVER_FORMATION_PATH = "/trouver-ma-formation";
export const LOGIN_PATH = "/connexion";
export const REGISTER_PATH = "/inscription";
export const FORGETPASSWORD = "/auth/forgetPassword";
export const RESETPASSWORD = "/aut/resetPassword";

/**
 * @readonly
 * ! PROTECTED ROUTES
 */
export const ACCEUIL_PATH = "/";
// ! CANDIRATURES PATHS
export const MES_CANDIDATURES_PATH = "/candidatures";
export const CANDIDATURE_PATH = ":slug_candidature";
export const NEW_CANDIDATURES_PATH = "nouvelle";
export const PROFILE_PATH = "/profile";

/**
 * @readonly
 * ! NOT FOUND ROUTE
 */
export const NOTFOUND_PATH = "*";
import React from "react"
// import CursusScolaireProvider from "./CursusScolaireProvider"
import Formulaire from "./Formulaire"

const CursusScolaire = ({ previewStep, nextStep }) => {
  return (
    <>
      <Formulaire previewStep={previewStep} nextStep={nextStep} />
    </>
  )
}

export default CursusScolaire
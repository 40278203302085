import { useGetDomainesQuery } from "actions/domaines/queries";
import { useMemo } from "react";

export const useFilieresDomaine = (domaineSlug) => {
  const { data: domaines } = useGetDomainesQuery();

  // console.log({ domaines: domaines?.results });
  const filieresDomaine = useMemo(() => {
    // recuperation des filieres d'un domaine
    const filieres = domaines?.results.find((domaine) => domaine.slug === domaineSlug)?.filieres || []
    // const domaine = domaines?.results.find((domaine) => {
    //   return domaine.slug === domaineSlug
    // })
    // console.log({ domaineSlug, filieres: domaine });
    return filieres;
  })

  return { filieresDomaine };
}
import { Drawer } from "antd"
import BlackLogo from "components/Logo/BlackLogo"
import React from "react"
import { NavLink } from "react-router-dom"

const MobileNavbar = ({ title, onClose, open, links, children }) => {
  return (
    <Drawer width={250} placement="top" title={<BlackLogo />} onClose={onClose} open={open}>
      <div className="flex flex-col justify-between h-full">
        <div>
          {
            links.map((link, index) => <>
              <NavLink
                to={link.path}
                key={index}
                onClick={onClose}
                // className=""
                className={({ isActive }) => isActive ?
                  "font-semibold py-3 px-2 bg-blue text-white block mb-3 rounded-md" : "font-semibold block mb-3"
                }
              >
                {link.label}
              </NavLink>
            </>)
          }
        </div>
        <div>
          {children}
        </div>
      </div>
    </Drawer>
  )
}

export default MobileNavbar
import { ApiMananger } from "actions/rtkquery";

const domainesApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        getDomaines: build.query({
            query: () => "visiteur/domaines/",
            invalidatesTags: ["DOMAINES"],
            keepUnusedDataFor: 3600000
        })
    }),
});

export const { useGetDomainesQuery } = domainesApi;
import { MES_CANDIDATURES_PATH, PROFILE_PATH } from "./navigationPaths";
import { HiHome } from "react-icons/hi";

/*
|--------------------------------------------------------------------------
! Sidebar Items
|--------------------------------------------------------------------------
|
| Ici se trouve tous les liens et icons qui doivent etre sur le sidebar
| de SplitShell
*/
export const links = [
    { path: MES_CANDIDATURES_PATH, icon: HiHome, label: "Mes candidatures" },
    { path: PROFILE_PATH, icon: HiHome, label: "Mon profil" },
]

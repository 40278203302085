import React from "react"
import { GrFormPrevious } from "react-icons/gr"
import { MdNavigateNext } from "react-icons/md"

import Button from "components/Button"
import CursusScolaireForm from "components/UserProfile/CursusScolaireForm"

const Formulaire = ({ previewStep, nextStep }) => {
  return (
    <div>
      <CursusScolaireForm />
      <div className="flex justify-end gap-5 sticky bottom-0 bg-white p-4 rounded-md">
        <Button
          title={"Précèdent"}
          onClick={previewStep}
          className="px-8 py-3 border border-blue bg-white"
          icon={{
            name: GrFormPrevious,
            color: "bg-blue",
            size: 18
          }}
        />
        <Button
          disabled={false}
          title={"Suivant"}
          onClick={nextStep}
          className="px-8 py-3 bg-blue text-white"
          icon={{
            name: MdNavigateNext,
            color: "#FFF",
            size: 18,
            position: "right"
          }}
        />
      </div>
    </div>
  )
}

export default Formulaire
import React from "react"
// import GridList from "react-flexible-list"
import EcoleCardItem from "./EcoleCardItem"
import { useNavigate } from "react-router-dom"

const ListeEcoles = ({ ecoles }) => {
    const navigate = useNavigate()
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* <GridList
                resourceData={ecoles}
                resourceItem={(ecole) => <EcoleCardItem ecole={ecole} onClick={() => navigate(ecole.slug)} />}
                cardWidth={200}
            /> */}
            {
                ecoles.map((ecole) => <EcoleCardItem key={ecole.id} ecole={ecole} onClick={() => navigate(ecole.slug)} />
                )
            }
        </div>
    )
}

export default ListeEcoles
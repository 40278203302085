import { ApiMananger } from "actions/rtkquery";

const candidatureApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        // /api/candidature/slug_de_la_candidature/soumisssion/
        soumettreCandidature: build.mutation({
            query({ body, slug }) {
                return {
                    url: `candidature/${slug}/soumission/`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["CANDIDATURES"],
        }),
    }),
});

export const { useSoumettreCandidatureMutation } = candidatureApi;
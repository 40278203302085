import React from "react"
import profile from "assets/imgs/Profile.svg"
import work from "assets/imgs/Work.png"
import documents from "assets/imgs/Document.png"

import AnimateOnRender from "components/AnimateRender/AnimateOnRender"
import UserProfileProvider from "features/private/Home/UserProfileProvider"
import CursusScolaireForm from "components/UserProfile/CursusScolaireForm"
import InformationsPersonnellesForm from "components/UserProfile/InformationsPersonnellesForm"
import { useSelector } from "react-redux"
import DocumentsAFournirForm from "components/UserProfile/DocumentsAFournirForm"
import { CiCircleInfo } from "react-icons/ci"

const Separator = () => {
  return <div className="my-8 h-[1px] bg-gray"></div>
}

const InfoSave = () => {
  return <div className="flex items-center gap-2 mb-3">
    <CiCircleInfo color="#f26e4f" /> <span className="text-sm text-[#f26e4f]">L'enregistrement est automatique</span>
  </div>
}

function HomePage() {
  const { currentUser } = useSelector((state) => state.user)
  return (
    <AnimateOnRender>
      <UserProfileProvider initialValues={currentUser.user}>
        <h2 className="text-blue text-md font-semibold uppercase mb-1 flex items-center gap-2">
          <img src={profile} alt="Informations personnelles" />Informations personnelles
        </h2>
        <InfoSave />
        <InformationsPersonnellesForm />
        <Separator />
        <h2 className="text-blue text-md font-semibold uppercase mb-1 flex items-center gap-2">
          <img src={work} alt="Cursus scolaire" />Cursus scolaire
        </h2>
        <InfoSave />
        <CursusScolaireForm />
        <Separator />
        <h2 className="text-blue text-md font-semibold uppercase mb-1 flex items-center gap-2">
          <img src={documents} alt="Documents" />Documents
        </h2>
        <InfoSave />
        <DocumentsAFournirForm />
      </UserProfileProvider>
    </AnimateOnRender>
  )
}

export default HomePage
import { ApiMananger } from "actions/rtkquery";

const etudiantCandidatures = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        getEtudiantCandidatures: build.query({
            query: (slug) => `etudiant/${slug}/candidatures`,
            invalidatesTags: ["CANDIDATURES"]
        }),
        getEtudiantCandidature: build.query({
            query: (slug) => `candidatures/${slug}`,
            invalidatesTags: ["CANDIDATURES"]
        })
    }),
});

export const { useGetEtudiantCandidaturesQuery, useGetEtudiantCandidatureQuery } = etudiantCandidatures;
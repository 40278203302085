/* eslint-disable react/react-in-jsx-scope */
import HomePage from "features/private/Home/HomePage";
import { ACCEUIL_PATH, CANDIDATURE_PATH, MES_CANDIDATURES_PATH, NEW_CANDIDATURES_PATH, PROFILE_PATH } from "../navigationPaths";
import RequireAuth from "layouts/RequireAuth";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
// import { Navigate } from "react-router-dom";
import SplitShell from "layouts/AppShells/SplitShell";
import CandidaterPage from "features/private/Candidatures/CandidaterPage";
import CandidaturesPage from "features/private/Candidatures/CandidaturesPage";
import { Outlet } from "react-router-dom";
import CandidaturesDetails from "features/private/Candidatures/Details/CandidaturesDetails";

/*
|---------------------------------------------------------------
| Les routes privates
|---------------------------------------------------------------
| Toute page qui necessite une authentification doit etre ici
|
*/
export const privateRoutes = [
    {
        path: ACCEUIL_PATH,
        errorElement: <ErrorBoundary />,
        element: <RequireAuth />,
        children: [
            {
                path: "",
                element: <SplitShell />,
                children: [
                    // {
                    //     path: "*",
                    //     element: <Navigate to={ACCEUIL_PATH} />
                    // },
                    {
                        path: PROFILE_PATH,
                        element: <HomePage />
                    },
                    {
                        path: MES_CANDIDATURES_PATH,
                        element: <Outlet />,
                        children: [
                            {
                                path: "",
                                element: <CandidaturesPage />
                            },
                            {
                                path: CANDIDATURE_PATH,
                                element: <CandidaturesDetails />
                            },
                            {
                                path: NEW_CANDIDATURES_PATH,
                                element: <CandidaterPage />
                            },
                        ]
                    },
                ]
            }
        ]
    },
];
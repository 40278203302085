import { ApiMananger } from "actions/rtkquery";

const villesApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        getVilles: build.query({
            query: () => "visiteur/villes/",
            invalidatesTags: ["VILLES"],
            keepUnusedDataFor: 3600000
        })
    }),
});

export const { useGetVillesQuery } = villesApi;
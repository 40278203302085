import React from "react"
import QuestionTitle from "../../../../components/TrouverFormation/QuestionTitle"
// import GridList from "react-flexible-list"
// import { trouverMaFormation } from "data/trouverMaFormation"
import { useDomaines } from "./useDomaines"
import LoadingIndicator from "../../../../components/Loader/LoadingIndicator"
import DomaineCardItem from "components/TrouverFormation/DomaineCardItem"
import RequestError from "components/RequestError/RequestError"
import EmptyData from "components/EmptyData/EmptyData"

const DomaineListChoix = () => {
    const { data, isError, isLoading } = useDomaines();

    if (isLoading) {
        return <LoadingIndicator />
    }
    if (isError) {
        return <RequestError />
    }

    return (
        <div>
            <QuestionTitle title={"Dans quel domaine souhaitez-vous effectuer votre formation ?"} />
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
                {
                    data && data.results.length ?
                        data.results.map((domaine, index) => <DomaineCardItem key={index} domaine={domaine} />) :
                        <EmptyData message={"Pas encore de domaines"} />
                }
            </div>
        </div>
    )
}

export default DomaineListChoix
import React, { cloneElement } from "react";

import SidebarItem from "../../components/SidebarItem";
import ResourceDataMap from "../../components/ResourceDataMap";
import { links } from "routes/navigation/sidebarLinks";
import avatar from "assets/imgs/avatar.png"
import { useSelector } from "react-redux";
import ProfileAvatar from "components/UI/ProfileAvatar";

const SidebarComponent = ({ bgColor, activeLinkColor, activeLinkBgColor, colorOnHover, linkColor }) => {
  const ClonedSidebarItem = ({ linkItem }) => cloneElement(<SidebarItem linkItem={linkItem} />, {
    activeLinkColor,
    activeLinkBgColor,
    colorOnHover,
    linkColor
  })
  const { currentUser } = useSelector((state) => state.user)
  // console.log({ currentUser });

  return (
    <div
      style={{
        backgroundColor: bgColor ? bgColor : "#fff",
        height: "100%",
        padding: "25px 10px",
        borderRight: "1px solid rgba(0,0,0,0.1)"
      }}
    >
      <div className="flex flex-col items-center gap-2 mb-6">
        {/* <div style={{ width: 100, height: 100, borderRadius: "100%", backgroundColor: "gray" }}></div> */}
        <ProfileAvatar avatar={avatar} />
        <div className="text-center">
          <p className="font-semibold">{currentUser?.user.prenom} {currentUser?.user.nom}</p>
          <span className="font-light text-sm">{currentUser?.data.email}</span>
        </div>
      </div>
      <ResourceDataMap
        resourceData={links}
        resourceItem={ClonedSidebarItem}
        resourceName="linkItem"
      />
    </div>
  );
};

export default SidebarComponent;

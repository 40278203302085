import React, { useState } from "react";

import InformationsPersonnelles from "../InformationsPersonnelles/InformationsPersonnelles";
import CursusScolaire from "../CursusScolaire/CursusScolaire";
import DocumentAFournir from "../DocumentsAFournir/DocumentAFournir";
import { useSoumettreCandidatureMutation } from "actions/candidatures/mutations";
import { useNavigate } from "react-router-dom";
import { MES_CANDIDATURES_PATH } from "routes/navigation/navigationPaths";
import { useSelector } from "react-redux";


export const useCandidature = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [soumettreCandidature, { isLoading: isSubmitingCandidature }] = useSoumettreCandidatureMutation()
  const navigate = useNavigate()
  const { currentUser } = useSelector((state) => state.user)
  const previewStep = () => {
    setCurrentIndex(currentIndex - 1)
  }
  const nextStep = () => {
    setCurrentIndex(currentIndex + 1)
  }
  const steps = [
    <InformationsPersonnelles nextStep={nextStep} key={0} />,
    <CursusScolaire key={1} previewStep={previewStep} nextStep={nextStep} />,
    <DocumentAFournir isSubmitingCandidature={isSubmitingCandidature} key={2} previewStep={previewStep} onSubmit={() => {
      soumettreCandidature({ body: { "status": "en_attente" }, slug: currentUser.data.slug }).unwrap()
        .then((res) => {
          console.log("Soummission reussie");
          navigate(MES_CANDIDATURES_PATH)
        })
        .catch((err) => {
          console.log({ err });
        })
    }} />
  ]

  return { currentIndex, previewStep, nextStep, steps }
}
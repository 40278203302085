import AppMaxWidth from "components/AppMaxWidth/AppMaxWidth"
import FiliereListChoix from "features/public/TrouverFormation/filieres/FiliereListChoix"
import DomaineListChoix from "features/public/TrouverFormation/domaines/DomaineListChoix"
import LieuEtudeListChoix from "features/public/TrouverFormation/lieux/LieuEtudeListChoix"
import React from "react"
// import ListeEcoles from "components/Ecoles/ListeEcoles"
// import DiplomeList from "components/Diplomes/DiplomeList"
import TrouverMaFormationeContexteProvider from "context/TrouverMaFormationeContexteProvider"
import DiplomeListChoix from "./diplomes/DiplomeListChoix"
import FormationsLists from "./formations/FormationsLists"

// const TrouverFormationContainer = ({ children }) => {
//     return <>{children}</>
// }

const TrouverFormation = () => {
    return (
        <AppMaxWidth>
            <TrouverMaFormationeContexteProvider>
                <DomaineListChoix />
                <FiliereListChoix />
                <DiplomeListChoix />
                <LieuEtudeListChoix />
                <FormationsLists />
                {/* <DiplomeList isImage={true} className="flex-col" /> */}
            </TrouverMaFormationeContexteProvider>
        </AppMaxWidth>
    )
}

export default TrouverFormation
import React from "react"
import { FaUser } from "react-icons/fa";
import { MdOutlineWork } from "react-icons/md";
import { TiDocumentText } from "react-icons/ti";
import { twMerge } from "tailwind-merge";

// import Button from "components/Button";
import { useCandidature } from "./useCandidature";
// import UserProfileProvider from "../../../Home/UserProfileProvider";

const Step = ({ icon, label, className, active }) => {
  return <div className={twMerge(["flex flex-col"], className)}>
    <div className={twMerge(["w-10 h-10 rounded-full flex justify-center items-center"], active ? "bg-blue" : "bg-gray")}>
      {icon}
    </div>
    <p className="font-semibold mt-1 text-center">{label}</p>
  </div>;
}

const CandidaterSteps = () => {
  const { currentIndex, steps } = useCandidature()

  return (
    <div>
      <div className="mt-14">
        <div className="border-t-2 border-gray w-full"></div>
        <div className="flex justify-between mt-[-20px]">
          <Step
            active={currentIndex >= 0}
            className=""
            icon={<FaUser color="white" size={18} />}
            label="Informations personnelles"
          />
          <Step
            active={currentIndex >= 1}
            className="items-center"
            icon={<MdOutlineWork color="white" size={18} />}
            label="Cursus Scolaire"
          />
          <Step
            active={currentIndex >= 2}
            className="items-end"
            icon={<TiDocumentText color="white" size={18} />}
            label="Document à fournir"
          />
        </div>
      </div>
      <div className="my-9 min-h-[400px]">
        {/* <UserProfileProvider> */}
        {steps[currentIndex]}
        {/* </UserProfileProvider> */}
      </div>
    </div>
  )
}

export default CandidaterSteps
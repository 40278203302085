import React from "react"
import { MdOutlineSchool } from "react-icons/md"

import AppMaxWidth from "components/AppMaxWidth/AppMaxWidth"
import SectionTitle from "components/Section/SectionTitle"
import Button from "components/Button"

const FormationsPlusRecherchees = () => {
    return (
        <AppMaxWidth>
            <SectionTitle title={"Les formations les plus recherchées"} />
            {/* flex md:justify-center gap-y-4 gap-x-6 mt-9 flex-wrap */}
            <div className="flex sm:flex-wrap sm:justify-center overflow-x-scroll sm:overflow-visible gap-4 mt-5 overscroll-x-none no-scrollbar">
                {
                    [1, 2, 3, 4, 5, 6, 7].map((formation, index) => {
                        // return <div key={index} className="flex-1 min-w-fit">
                        return <Button
                            key={index}
                                title={"Licence système et réseau"}
                                icon={{
                                    name: MdOutlineSchool,
                                    color: "rgba(17, 17, 165, 1)",
                                    size: 25
                                }}
                            className="bg-none min-w-fit text-black text-center text-md font-medium border py-2 border-gray"
                            />
                        // </div>
                    })
                }
            </div>
        </AppMaxWidth>
    )
}

export default FormationsPlusRecherchees
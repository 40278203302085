import React from "react"
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";

import Button from "components/Button"
import Input from "components/Input"
import BlackLogo from "components/Logo/BlackLogo";
import { Link } from "react-router-dom";
import { REGISTER_PATH } from "routes/navigation/navigationPaths";
import { useFormContext } from "react-hook-form";
import { useConnexion } from "features/public/Connexion/useConnexion";

const ConnexionForm = () => {
  const { register, handleSubmit, formState: { errors } } = useFormContext()
  const { handleConnexion, isConnexionLoading } = useConnexion()
  return (
    <div className="py-6">
      <div className="flex justify-center mt-5">
        <BlackLogo />
      </div>
      <h2 className="text-center text-2xl text-blue font-semibold mb-6">Connectez-vous</h2>
      <div className="mb-9 flex items-center gap-9 flex-wrap">
        <button className="min-w-fit border px-6 py-3 border-gray rounded-md bg-[#F2F2F2] font-medium flex items-center justify-center gap-2 flex-1">
          <FcGoogle size={25} />
          Continuer avec Google
        </button>
        <button className="min-w-fit border px-6 py-3 border-gray rounded-md bg-[#F2F2F2] font-medium flex items-center justify-center gap-2 flex-1">
          <FaFacebookF size={25} />
          Continuer avec Facebook
        </button>
      </div>
      <div className="border-t border-gray relative mb-4">
        <span className="bg-white w-fit shadow-sm rounded-full p-2 absolute translate-[-50%, -50%] z-10 left-[47%] right-[50%] top-[-20px] font-semibold">Ou</span>
      </div>
      <form onSubmit={handleSubmit(handleConnexion)}>
        <div className="mt-4">
          {/* <div className="flex-1"> */}
          <Input
            label="Email" placeholder="Email"
            register={{ ...register("email") }}
            errorMessage={errors.email?.message}
          />
          {/* </div> */}
          <div className="mt-6">
            <Input
              label="Mot de passe" placeholder="Mot de passe"
              register={{ ...register("password") }}
              errorMessage={errors.password?.message}
            />
          </div>
        </div>
        {/* <div className="flex justify-end"> */}
        <Button
          title="Suivant"
          disabled={isConnexionLoading}
          loading={isConnexionLoading}
          className="bg-blue text-white px-6 py-3 w-full mt-6 hover:bg-[#003f96] hover:text-white"
        />
        {/* </div> */}
      </form>
      <p className="mt-5">Vous n'avez pas de compte? <Link to={REGISTER_PATH} className="text-blue">Inscrivez-vous</Link></p>
    </div>
  )
}

export default ConnexionForm
import React from "react"
import { useNavigate } from "react-router-dom";
import { MdOutlineSchool } from "react-icons/md";

import Button from "components/Button"
import imgBanner from "assets/imgs/hero-img.png"
import { TROUVER_FORMATION_PATH } from "routes/navigation/navigationPaths";

const Banner = () => {
    const navigate = useNavigate()
    return (
        <section className="m-h-[500px] lg:h-[500px] bg-blue px-6">
            <div className="max-w-7xl m-auto md:grid lg:grid-cols-2 md:items-start gap-10 px-0 py-10 md:py-3 lg:py-12">
                <div className="max-w-xl text-left">
                    <h1 className="font-bold text-[45px] sm:text-5xl leading-[70px] sm:leading-[4rem] text-white">
                        La plateforme qui vous aide à trouver votre formation
                    </h1>

                    <p className="hidd mt-5 md:mt-4 md:block text-white">
                        Avec Unistaf, trouvez les écoles habilitées et les diplômes accréditées au Sénégal !
                    </p>

                    <div className="flex flex-wrap gap-3 mt-10">
                        <Button
                            onClick={() => navigate(TROUVER_FORMATION_PATH)}
                            className="bg-white hover:opacity-90 text-blueSecondary text-center h-11 px-10"
                            title={"Trouver une formation"}
                            icon={{
                                name: MdOutlineSchool,
                                color: "rgba(75, 75, 239, 1)",
                                size: 25
                            }}
                        />
                    </div>
                </div>

            {/* <div className="relative"> */}
            <img
                    alt="La plateforme qui vous aide à trouver votre formation"
                src={imgBanner}
                    className="h-[500px] hidden md:block w-full object-cover sm:self-end sm:rounded-ss-[30px] md:rounded-ss-[100px] md:rounded-br-[100px] rounded-md"
            />
            </div>
            {/* </div> */}
        </section>
    )
}

export default Banner
import { ApiMananger } from "actions/rtkquery";

const formationsApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        getFormations: build.query({
            query: (params) => {
                return {
                    url: "visiteur/formations/",
                    params
                }
            },
            invalidatesTags: ["FORMATIONS"],
            keepUnusedDataFor: 10
        }),
        getCandidatureFormations: build.query({
            query: (params) => {
                return {
                    url: "visiteur/formations/search/",
                    params
                }
            },
            invalidatesTags: ["FORMATIONS"],
            keepUnusedDataFor: 10
        })
    }),
});

export const { useGetFormationsQuery, useGetCandidatureFormationsQuery } = formationsApi;
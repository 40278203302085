import React from "react"
import DocumentsAFournirForm from "components/UserProfile/DocumentsAFournirForm"
import Button from "components/Button"
import { GrFormPrevious } from "react-icons/gr"
import { MdSend } from "react-icons/md"

const DocumentAFournir = ({ isSubmitingCandidature, previewStep, onSubmit }) => {
  return (
    <>
      <DocumentsAFournirForm />
      <div className="flex justify-end gap-5 sticky bottom-0 bg-white p-4 rounded-md">
        <Button
          disabled={isSubmitingCandidature}
          title={"Précèdent"}
          onClick={previewStep}
          className="px-8 py-3 border border-blue bg-white"
          icon={{
            name: GrFormPrevious,
            color: "bg-blue",
            size: 18
          }}
        />
        <Button
          disabled={isSubmitingCandidature}
          loading={isSubmitingCandidature}
          title={"Postuler"}
          onClick={onSubmit}
          className="px-8 py-3 bg-blue text-white"
          icon={{
            name: MdSend,
            color: "#FFF",
            size: 18,
            position: "right"
          }}
        />
      </div>
    </>
  )
}

export default DocumentAFournir
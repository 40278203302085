import React from "react"
import InformationsPersonnellesProviver from "./InformationsPersonnellesProvider"
import { useSelector } from "react-redux"
import Formulaire from "./Formulaire"

const InformationsPersonnelles = ({ nextStep }) => {
  const { currentUser } = useSelector((state) => state.user)

  // console.log({ currentUser });


  return (
    <InformationsPersonnellesProviver initialValues={currentUser.user}>
      <Formulaire nextStep={nextStep} />
    </InformationsPersonnellesProviver>
  )
}

export default InformationsPersonnelles
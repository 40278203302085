import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    // cursus
    etablissement: yup.string().required("Etablissement obligatoire"),
    annee: yup.string().required("Année obligatoire"),
    ville: yup.string().required("Ville obligatoire"),
    diplome: yup.string().required("Diplôme obligatoire"),
  })
  .required();


const CursusScolaireProvider = ({ children, initialValues }) => {
  const methods = useForm({
    values: { ...initialValues },
    defaultValues: { ...initialValues },
    resolver: yupResolver(schema),
  })
  return (
    <FormProvider {...methods}>{children}</FormProvider>
  )
}

export default CursusScolaireProvider

export const getCursusInitialValues = (data) => {
  return {
    etablissement: "",
    annee: "",
    ville: "",
    diplome: ""
  }
}
import { ApiMananger } from "actions/rtkquery";

const currentUserApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        updatePersonalInfos: build.mutation({
            query({ body, slug }) {
                return {
                    url: `etudiants/${slug}/`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["CURRENT_USER"],
        }),
        addCursus: build.mutation({
            query(body) {
                return {
                    url: "cursus/",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["CURRENT_USER"],
        }),
        addDocument: build.mutation({
            query(body) {
                return {
                    url: "documents/",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["CURRENT_USER"],
        }),
        updateDocument: build.mutation({
            query({ body, slug }) {
                return {
                    url: `documents/${slug}/`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["CURRENT_USER"],
        }),
    }),
});

export const {
    useUpdatePersonalInfosMutation,
    useAddCursusMutation,
    useAddDocumentMutation,
    useUpdateDocumentMutation
} = currentUserApi;
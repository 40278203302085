import React from "react"
import blackLogo from "assets/logos/unistaf-black-logo.png"

import styled, { keyframes } from "styled-components";

// Define keyframes for animation
const l2 = keyframes`
  to {
    background-size: 100% 3px;
  }
`;

// Create a styled component for the loader
const Loader = styled.div`
  width: fit-content;
  font-weight: normal !important;
  font-family: sans-serif;
  font-size: 20px;
  padding-bottom: 8px;
  background: linear-gradient(currentColor 0 0) 0 100% / 0% 3px no-repeat;
  animation: ${l2} 2s linear infinite;

  // Pseudo-element for "Loading..."
  &::before {
    content: "Loading...";
  }
`;

const SuspenseLoader = () => {
    const orientations = [
        "Définissez votre parcours et votre projet d’orientation.",
        "Recherche des meilleures formations reconnues par l'état Sénégalais.",
        "Bénéficiez de l'appel gratuit d’un conseiller d’orientation UNISTAF."
    ]
    return (
        <div className="w-full h-[100vh] flex flex-col gap-2 items-center justify-center px-4">
            <img src={blackLogo} alt="Unistaf" className="w-32" />
        <p className="text-md text-center font-normal">{orientations[Math.floor(Math.random() * 3)]}</p>
            <Loader />
        </div>
    )
}

export default SuspenseLoader
export function convertYouTubeUrl(url) {
  let videoId;
  if (!url) return null
  if (url.includes("watch?v=")) {
    // Cas pour les URLs de type "watch?v="
    videoId = url.split("watch?v=")[1].split("&")[0];
  } else if (url.includes("youtu.be/")) {
    // Cas pour les URLs de type "youtu.be/"
    videoId = url.split("youtu.be/")[1].split("?")[0];
  } else if (url.includes("youtube.com/embed/")) {
    // Cas pour les URLs de type "embed"
    videoId = url.split("embed/")[1].split("?")[0];
    return url.split("?")[0]; // Retourne l'URL embed sans les paramètres
  } else {
    return "URL non valide";
  }

  return `https://www.youtube.com/embed/${videoId}`;
}
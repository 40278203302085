import Button from "components/Button"
import RadioInput from "components/Form/RadioInput"
import Input from "components/Input"
import BlackLogo from "components/Logo/BlackLogo"
import React from "react"
import { useFormContext } from "react-hook-form"
import { Link } from "react-router-dom"
import { LOGIN_PATH } from "routes/navigation/navigationPaths"
import { useInscription } from "./useInscription"

const InscriptionForm = () => {
  const { register, handleSubmit, formState: { errors } } = useFormContext()
  const { handleInscription, isInscriptionLoading } = useInscription()

  return (
    <div>
      <div className="flex justify-center mt-5">
        <BlackLogo />
      </div>
      <h2 className="text-center text-2xl text-blue font-semibold mb-5">Inscrivez-vous</h2>
      <form onSubmit={handleSubmit(handleInscription)}>
        <label className="font-semibold text-blue">Genre</label>
        <div className="flex gap-4">
          <RadioInput errorMessage={errors.civilite?.message} register={{ ...register("civilite") }} value={"homme"} name={"civilite"} label="Homme" />
          {/* Féminin */}
          <RadioInput errorMessage={errors.civilite?.message} register={{ ...register("civilite") }} value={"femme"} name={"civilite"} label="Femme" />
        </div>
        <div className="flex flex-wrap gap-4">
          <div className="flex-1">
            <Input
              label="Prénom" placeholder="Prénom"
              register={{ ...register("prenom") }}
              errorMessage={errors.prenom?.message}
            />
          </div>
          <div className="flex-1">
            <Input
              label="Nom" placeholder="Nom"
              register={{ ...register("nom") }}
              errorMessage={errors.nom?.message}
            />
          </div>
        </div>
        <div className="flex flex-wrap gap-4 mt-4">
          <div className="flex-1">
            <Input
              label="Email" placeholder="Email"
              register={{ ...register("email") }}
              errorMessage={errors.email?.message}
            />
          </div>
          <div className="flex-1">
            <Input
              type={"password"}
              label="Mot de passe" placeholder="Mot de passe"
              register={{ ...register("password") }}
              errorMessage={errors.password?.message}
            />
          </div>
        </div>
        <div className="mt-4">
          <RadioInput label="Condition générale d'utilisation" />
        </div>
        {/* <div className="flex justify-end"> */}
        <Button
          disabled={isInscriptionLoading}
          loading={isInscriptionLoading}
          title="Suivant"
          className="bg-blue text-white px-6 py-3 w-full mt-6 hover:bg-[#003f96] hover:text-white"
        />
      </form>
      {/* </div> */}
      <p className="mt-5">Vous avez déjà compte? <Link to={LOGIN_PATH} className="text-blue">Connectez-vous</Link></p>
    </div>
  )
}

export default InscriptionForm
import React from "react"
import { CiGlobe } from "react-icons/ci"
import { FaFacebook } from "react-icons/fa"
import { HiPhone } from "react-icons/hi"
import { MdEmail, MdLocationCity } from "react-icons/md"

const ContactItem = ({ icon: Icon, label }) => {
    return <p className="flex items-center gap-3">
        <Icon.name />
        <span>{label}</span>
    </p>
}

const ContactEcole = ({ adresse, telephone, email, facebook, site_web }) => {
    return (
        <div className="shadow-md min-w-[300px] p-5 rounded-lg">
            <h4 className="text-xl font-semibold mb-3">Contact</h4>
            <ContactItem
                icon={{
                    name: MdLocationCity
                }}
                label={adresse}
            />
            <ContactItem
                icon={{
                    name: HiPhone
                }}
                label={telephone}
            />
            <ContactItem
                icon={{
                    name: MdEmail
                }}
                label={email}
            />
            <div className="flex gap-2 items-center"></div>
            <a href={facebook} target="_blank" rel="noreferrer">
                <ContactItem
                    icon={{
                        name: FaFacebook
                    }}
                    label={"Facebook"}
                />
            </a>
            <a href={site_web} target="_blank" rel="noreferrer">
                {/* <CiGlobe size={25} /> */}
                <ContactItem
                    icon={{
                        name: CiGlobe
                    }}
                    label={"Site web"}
                />
            </a>
        </div>
    )
}

export default ContactEcole
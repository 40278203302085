import { createSlice } from "@reduxjs/toolkit";

const trouverFormationSlice = createSlice({
  name: "trouverFormation",
  // TODO: {data, currentIndex}
  initialState: {
    selectedDomaine: { libelle: "" },
    selectedFiliere: { libelle: "" },
    selectedDiplome: { libelle: "" },
    selectedLieuFormation: { libelle: "" },
    selectedFormation: { libelle: "" }
  },
  reducers: {
    setDomaine: (state, { payload }) => {
      return {
        ...state,
        selectedDomaine: payload
      }
    },
    setFiliere: (state, { payload }) => {
      return {
        ...state,
        selectedFiliere: payload
      }
    },
    setDiplome: (state, { payload }) => {
      return {
        ...state,
        selectedDiplome: payload
      }
    },
    setLieuFormation: (state, { payload }) => {
      return {
        ...state,
        selectedLieuFormation: payload
      }
    },
    setFormation: (state, { payload }) => {
      return {
        ...state,
        selectedFormation: payload
      }
    },
  }
})
export const { setDomaine, setFiliere, setDiplome, setLieuFormation, setFormation } = trouverFormationSlice.actions
export default trouverFormationSlice.reducer
import { useGetCurrentUserQuery } from "actions/auth/queries";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initializeCursusForm, initializeDocumentsForm, setCurrentUserProfile } from "redux/features/userslice";

function useGetCurrentUser() {
  // const user = null;
  const dispatch = useDispatch()
  const { data } = useGetCurrentUserQuery()

  useEffect(() => {
    if (data) {
      // console.log("getCurrentUser");
      // recuperer le current user afin de mettre a jour sont profil: cursus, documents, etc
      dispatch(setCurrentUserProfile(data))
      // on initialise le formulaire des cursus
      // console.log("initializeCursusForm ", data?.cursus);
      dispatch(initializeCursusForm(data?.cursus))
      // TODO: a revoir
      if ((data.documents.length >= 3)) {
        console.log("on initialise le formulaire ", data.documents);
        dispatch(initializeDocumentsForm(data.documents))
      }
    }
  }, [])
}

export default useGetCurrentUser;

import { useGetEcolesQuery } from "actions/ecoles/queries"

const useEcoles = () => {
  const { data = { results: [] }, isLoading, isError
  } = useGetEcolesQuery()
  console.log(data);

  return { ecoles: data, isLoading, isError }
}

export default useEcoles
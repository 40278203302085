import React from "react"

const EcoleInfo = ({ type_etablissement }) => {
    return (
        <div className="shadow-md min-w-[300px] p-5 rounded-lg">
            <h3 className="text-xl font-semibold">Apropos</h3>
            <div className="flex items-center gap-2 mt-3">
                <span>Type d'etablissement :</span>
                <span className="font-semibold">{type_etablissement}</span>
            </div>
        </div>
    )
}

export default EcoleInfo
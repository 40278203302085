import React from "react";
import Button from "components/Button";
import InformationsPersonnellesForm from "components/UserProfile/InformationsPersonnellesForm";
import { useFormContext } from "react-hook-form";
import { BsFillSave2Fill } from "react-icons/bs";
import useInformationsPersonnelles from "./useInformationsPersonnelles";

const Formulaire = ({ nextStep }) => {
  const { handleSubmit } = useFormContext()
  const { handleSubmitInformationsPersonnelles, isUpdatingPersonnalInfos } = useInformationsPersonnelles()
  // console.log({ handleSubmit });

  const handleSubmitForm = (data) => {
    handleSubmitInformationsPersonnelles(data, nextStep)
    // console.log({ data });
    // nextStep()
  }
  return <form onSubmit={handleSubmit(handleSubmitForm)}>
    <InformationsPersonnellesForm />
    <div className="flex justify-end gap-5 sticky bottom-0 bg-white p-4 rounded-md">
      <Button
        disabled={isUpdatingPersonnalInfos}
        loading={isUpdatingPersonnalInfos}
        title={"Enregistrer en continuer"}
        className="px-6 py-3 bg-blue text-white"
        icon={{
          name: BsFillSave2Fill,
          color: "bg-blue",
          size: 18
        }}
      />
    </div>
  </form>
}

export default Formulaire
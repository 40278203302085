import LoadingIndicator from "components/Loader/LoadingIndicator"
import RequestError from "components/RequestError/RequestError"
import React from "react"

const SuspenseBoundary = ({ isLoading, isError, children }) => {
  if (isLoading) {
    return <LoadingIndicator />
  }
  if (isError) {
    return <RequestError />
  }
  return (
    <>{children}</>
  )
}

export default SuspenseBoundary
import React from "react"

const RadioInput = ({ label, name, value, register, errorMessage }) => {
  return (
    <div className="mb-4">
      <div className="flex items-center">
        <input
          id="default-radio-1"
          type="radio"
          value={value}
          name={name}
          {...register}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
        <label htmlFor={label} className="ms-2 text-md font-medium text-gray-900 dark:text-gray-300">{label}</label>
      </div>
      {
        errorMessage && (
          <div style={{ marginTop: 10, fontSize: 13, color: "red" }}>
            {errorMessage}
          </div>
        )
      }
    </div>
  )
}

export default RadioInput
import { useUpdatePersonalInfosMutation } from "actions/user/mutations"
import { useDispatch, useSelector } from "react-redux"
import { setCurrentUserProfile } from "redux/features/userslice"


const useInformationsPersonnelles = () => {
  const [mutation, { isLoading: isUpdatingPersonnalInfos }] = useUpdatePersonalInfosMutation()
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state.user)
  const handleSubmitInformationsPersonnelles = (data, nextStep) => {
    mutation({ body: data, slug: currentUser?.user.slug }).unwrap().then((res) => {
      dispatch(setCurrentUserProfile(res))
      nextStep()
    }).catch((err) => {
      console.log({ err });
    })
  }

  return { handleSubmitInformationsPersonnelles, isUpdatingPersonnalInfos }
}

export default useInformationsPersonnelles
import { useLoginUserMutation } from "actions/auth/mutations"
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentUser } from "redux/features/userslice";
import { MES_CANDIDATURES_PATH, NEW_CANDIDATURES_PATH } from "routes/navigation/navigationPaths";

export const useConnexion = () => {
  const [loginMutation, { isLoading: isConnexionLoading }] = useLoginUserMutation()
  const { selectedFormation } = useSelector((state) => state.trouverFormation)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleConnexion = (data) => {
    console.log({ data });

    loginMutation(data).unwrap().then((res) => {
      console.log({ res });
      dispatch(setCurrentUser(res))
      if (selectedFormation) {
        navigate(MES_CANDIDATURES_PATH + "/" + NEW_CANDIDATURES_PATH)
      } else {
        navigate(MES_CANDIDATURES_PATH)
      }
    }).catch((err) => {
      console.log({ err });
      toast.error(err.data.message)
    })
  }

  return { handleConnexion, isConnexionLoading }
}
import React from "react"
import anaqsup from "assets/imgs/anaqsup.png"

const Anqsup = () => {
  return (
    <div className="bg-blueRgba rounded-full flex items-center px-4">
      <img src={anaqsup} alt="Anaqsup" className="object-cover" />
    </div>
  )
}

export default Anqsup;
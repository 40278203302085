import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    email: yup.string().required("Email obligatoire"),
    password: yup.string().required("Mot de passe obligatoire")
  })
  .required();


const ConnexionProvider = ({ children }) => {
  const methods = useForm({
    values: {},
    defaultValues: {},
    resolver: yupResolver(schema),
  })
  return (
    <FormProvider {...methods}>{children}</FormProvider>
  )
}

export default ConnexionProvider
import React from "react"
import InscriptionForm from "./InscriptionForm"
import InscriptionProvider from "./InscriptionProvider"

const InscriptionPage = () => {
  return (
    <div className="max-w-2xl m-auto shadow-sm h-[100vh] flex justify-center items-center">
      <div className="px-4 flex-1">
        <InscriptionProvider>
          <InscriptionForm />
        </InscriptionProvider>
      </div>
    </div>
  )
}

export default InscriptionPage
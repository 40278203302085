import React, { memo } from "react"
import QuestionTitle from "../../../../components/TrouverFormation/QuestionTitle"
// import GridList from "react-flexible-list"
// import { trouverMaFormation } from "data/trouverMaFormation"
import TrouverFormationCard from "../../../../components/TrouverFormation/TrouverFormationCard"
import { useTrouverMaFormationeContexte } from "context/TrouverMaFormationeContexteProvider"
import { useFilieresDomaine } from "./useFilieres"
import { useSearchParams } from "react-router-dom"

const FiliereChoixCardItem = ({ filiere }) => {
    const { setFiliereChoice, trouverFormation } = useTrouverMaFormationeContexte()
    return <TrouverFormationCard
        customClass={"rounded-full text-sm"}
        active={trouverFormation.selectedFiliere?.id === filiere.id}
        onClickCard={() => setFiliereChoice(filiere)}
        content={() => <span className="font-semibold text-center">{filiere.libelle}</span>}
    />
}
const FiliereListChoix = () => {
    const [searchParams,] = useSearchParams();
    const { filieresDomaine } = useFilieresDomaine(searchParams.get("domaine"));
    // console.log({ filieresDomaine });
    return (
        <div>
            <QuestionTitle title={"Choisissez votre filière de formation"} />
            {/* <GridList
                resourceData={trouverMaFormation.filieres}
                resourceItem={(filiere) => <FiliereChoixCardItem filiere={filiere} />}
                cardWidth={300}
            /> */}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                {filieresDomaine.map((filiere, index) => <FiliereChoixCardItem key={index} filiere={filiere} />)}
            </div>
        </div>
    )
}

export default memo(FiliereListChoix)
import React from "react"

import AnimateOnRender from "components/AnimateRender/AnimateOnRender"
import CandidaterSteps from "features/private/Candidatures/Candidature/Multistep/CandidaterSteps"
import DiplomeCardItem from "components/Diplomes/DiplomeCardItem"
import { useSelector } from "react-redux";

const CandidaterPage = () => {
  const { selectedFormation } = useSelector((state) => state.trouverFormation)
  const { currentUser } = useSelector((state) => state.user)
  // console.log({ selectedFormation });

  // const diplomeData = {
  //   libelle: "Master en Informatique",
  //   domaine: {
  //     libelle: "Technologies de l'Information"
  //   },
  //   type: "Formation Continue",
  //   accreditations: "AACSB",
  //   diplome: {
  //     libelle: "Master"
  //   },
  //   lieu: "Paris, France"
  // };

  return (
    <AnimateOnRender>
      <div className="flex gap-6">
        <div className="pe-12">
          <h3 className="font-semibold text-2xl">Bienvenu {currentUser.user.prenom},</h3>
          <p className="font-medium">Complétez inscription pour valider</p>
        </div>
        <DiplomeCardItem extraClasses={"border border-gray rounded-xl"} diplome={selectedFormation} />
      </div>
      <CandidaterSteps />
    </AnimateOnRender>
  )
}

export default CandidaterPage